<template>
  <div class="">
    <navtop :navList="navList">
      <div slot="right" class="muted">
        有疑问可联系我们：{{ config.purchase_phone }}
      </div>
    </navtop>
    <div class="info-box">
      <div class="info-content">
        <div class="content-item row">
          <div class="item-title">收货地址：</div>
          <div class="item-content lighter">
            <p>
              {{ orderDetail.delivery_address }} 　{{ orderDetail.cost_unit ? orderDetail.cost_unit+'　' : '' }} {{ orderDetail.consignee }}收
              {{ orderDetail.mobile }}
            </p>
          </div>
        </div>
        <div class="content-item row">
          <div class="item-title">订单类型：</div>
          <div class="item-content lighter">
            <p>{{ orderDetail.order_type }}</p>
          </div>
        </div>
        <div class="content-item row">
          <div class="item-title">订单信息：</div>
          <div class="item-content lighter">
            <p>订单编号：{{ orderDetail.order_sn }}</p>
            <p v-if="orderDetail.finance_status != 2 && orderDetail.manager_status != 2">
              创建时间：{{ orderDetail.create_time }}　　
            </p>
            <p v-if="orderDetail.finance_status != 2 && orderDetail.manager_status != 2 && orderDetail.pay_btn == 1">
              付款状态：未付款</p>
            <p v-if="orderDetail.finance_status != 2 && orderDetail.manager_status != 2 && orderDetail.pay_btn != 1">
              付款时间：{{
                  orderDetail.pay_time
              }}</p>
          </div>
        </div>

        <div class="content-item row"   v-if="this.$store.getters.userInfo.company_id == 61">
          <div class="item-title">湘雅信息：</div>
          <div class="item-content lighter">            
            <p>湘雅申请单号:{{ orderDetail.xy_request_no }}</p>
            <p >湘雅成本单元:{{ orderDetail.xy_cost_unit }}</p>
            <p >湘雅订单号:{{orderDetail.xy_order_sn }}</p>
          </div>
        </div>


        <div class="content-item row" v-if="orderDetail.finance_status == 2 || orderDetail.manager_status == 2">
          <div class="item-title">订单状态：</div>
          <div class="item-content lighter price">
            <p>{{ orderDetail.finance_status == 2 ? '财务驳回' : '经理驳回' }}</p>
          </div>
        </div>
        <div class="content-item row" v-if="!orderDetail.pay_btn">
          <div class="item-title">支付方式：</div>
          <div class="item-content lighter">
            <p>{{ orderDetail.pay_way }}</p>
          </div>
        </div>
        <div class="content-item row">
          <div class="item-title">订单备注：</div>
          <div class="item-content lighter">
            <p>{{ orderDetail.user_remark || '-' }}</p>
          </div>
        </div>
      </div>
      <div class="info-table">
        <div class="table-item item-th row">
          <div class="flex3"><span class="pd">商品</span></div>
          <div class="flex2 row-center">属性</div>
          <div class="flex2 row-center">单价</div>
          <div class="flex2 row-center">数量</div>
          <div class="flex2 row-center">总金额</div>
          <div class="flex2 row-center" v-if="orderDetail.finance_status != 2 && orderDetail.manager_status != 2">状态
          </div>
        </div>
        <div class="table-item row" v-for="(item, index) in orderDetail.order_goods" :key="index">
          <div class="flex3 item-pro row">
            <el-image :src="item.image" fit="cover" class="img"></el-image>
            <p class="line2">{{ item.goods_name }}</p>
          </div>
          <div class="flex2 row-center lighter">{{ item.spec_value }}</div>
          <div class="flex2 row-center">
            <make-priceformat :price="item.goods_price" :subscriptSize="17" :firstSize="17" :secondSize="17"
              color="#777777"></make-priceformat>
          </div>
          <div class="flex2 row-center">{{ item.goods_num }}</div>
          <div class="flex2 row-center">
            <make-priceformat :price="item.total_pay_price" :subscriptSize="17" :firstSize="17" :secondSize="17"
              color="#777777"></make-priceformat>
          </div>
          <div class="flex2 row-center" v-if="orderDetail.finance_status != 2 && orderDetail.manager_status != 2">
            <span v-if="orderDetail.pay_btn">待支付</span>
            <!-- <span v-if="!orderDetail.pay_btn && !orderDetail.comment_btn && !orderDetail.refund_btn">已支付</span> -->
            <button class="bg-center" v-if="orderDetail.comment_btn">
              评价</button><button class="bg-center" v-if="orderDetail.refund_btn">
              退款
            </button>
          </div>
        </div>
        <div class="row-end mt30" v-if="orderDetail.finance_status != 2 && orderDetail.manager_status != 2">
          <div class="column">
            <div class="row-end">
              <span>{{ orderDetail.pay_btn == 1 ? "待支付" : "实付款" }}：</span>
              <make-priceformat :price="orderDetail.total_amount" :subscriptSize="22" :firstSize="32" :secondSize="32"
                weight="bold"></make-priceformat>
            </div>
            <div class="row-end mt10 muted" style="line-height: 1">
              <span> 满减金额：</span>
              <make-priceformat :price="orderDetail.reduct_amount" :subscriptSize="16" :firstSize="16" :secondSize="16"
                color="#777777" class="mr5" :showSubscript="false"></make-priceformat><span>元</span>
            </div>
            <div class="row-end mt10 muted" style="line-height: 1">
              <span> 邮费：</span>
              <make-priceformat :price="orderDetail.shipping_price" :subscriptSize="16" :firstSize="16" :secondSize="16"
                color="#777777" class="mr5" :showSubscript="false"></make-priceformat><span>元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="payment mt30"
      v-if="orderDetail.pay_btn && orderDetail.finance_status == 1 && orderDetail.manager_status == 1 && (commonData.group_id == 1 || commonData.group_id == 4)">
      <div class="title lg normal mb30">选择支付方式</div>
      <div class="pay-type">
        <el-radio-group v-model="pay_way" size="medium">
          <el-radio :label="1">微信支付</el-radio>
          <el-radio :label="2">支付宝支付</el-radio>
          <el-radio :label="4">线下支付</el-radio>
          <el-radio :label="5" v-if="orderDetail.is_quota_pay">额度支付（剩余额度<make-priceformat
              :price="orderDetail.quota_balance" :subscriptSize="16" :firstSize="16" :secondSize="16" class="ml5">
            </make-priceformat>）</el-radio>
            <el-radio :label="6" v-if="orderDetail.is_quota_pay">混合支付
            </el-radio>
        </el-radio-group>
      </div>
      <div class="pay-type" v-if="pay_way==6" style="margin-top: 20px;display: flex;justify-content: end;">
        额度支付（剩余额度<make-priceformat
              :price="orderDetail.quota_balance" :subscriptSize="16" :firstSize="16" :secondSize="16" class="ml5">
            </make-priceformat>）：<input type="text" placeholder="请输入金额" v-model="order_amounts" style="width: 100px;" />
              <el-radio-group v-model="pay_ways" size="medium">
                  <el-radio :label="1">微信支付</el-radio>
                  <el-radio :label="2">支付宝支付</el-radio>
              </el-radio-group>
              </div>
      <div class="pay-list row">
        <div class="pay-item mt20 normal nr"></div>
      </div>
      <div class="row-end" @click="surePay">
        <button class="bg-primary white pay-btn md mt20 mb30">立即支付</button>
      </div>
    </div>

    <el-dialog title="" :visible.sync="wxDialog" width="50%" destroy-on-close :before-close="handleClose">

      <div class="wx-box">
        <div class="icon-box">
          <img class="wx-icon" src="@/assets/wx-pay.png" alt="">
          <div class="wx-text">
            请使用微信扫码付款
          </div>
        </div>
        <img class="wx-img" :src="wxImg" alt="">
      </div>
    </el-dialog>

  </div>
</template>
<script>
import NavTop from "../components/NavTop";
import { mapState } from "vuex";
export default {
  name: "",
  props: {},
  components: {
    navtop: NavTop,
  },
  data() {
    return {
      navList: ["订单信息"],
      orderId: "",
      orderDetail: {},
      pay_way: 5,
      pay_ways: 5,
      order_amounts:"请输入金额",
      timer: null,

      wxDialog: false,
      wxImg: "",
    };
  },
  computed: {
    ...mapState(["config"]),
    ...mapState({
      commonData: (state) => state.user.commonData,
    }),
  },
  watch: {},
  methods: {
    // 关闭微信支付
    handleClose() {
      this.wxDialog = false
    },
    /**
     * @description 获取订单详情
     * @return void
     */
    getOrderDetail() {
      this.$api
        .getOrderDetailApi({
          id: this.orderId,
        })
        .then((res) => {
          this.orderDetail = res.data;
        });
    },
    addExpress() {
      this.timer = setInterval(() => {
        this.$api
          .getPayResultApi({
            id: this.orderId,
            from: "trade",
          })
          .then((res) => {
            if (res.code == 1) {
              clearInterval(this.timer);
            }
          });
      }, 3000);
    },
    /**
     * @description 立即支付
     * @return void
     */
    async surePay() {
      let { code, msg, data } = await this.$api.unifiedpayApi({
          from: "order",
          order_id: this.orderId,
          pay_way: this.pay_way,
          pay_ways:this.pay_way==6?this.pay_ways:0,
          order_amounts:this.pay_way==6?Number(this.order_amounts):0
        });
      if (code == 1 || code == 10001) {
        this.$message({
          message: msg,
          type: "success",
        });
        switch (this.pay_way) {
          case 1:
            // this.wxDialog = true
            // this.wxImg = data
            this.$router.push({
              name: "Pay",
              // query: {
              //   id: this.orderId,
              //   from: "order",
              //   type:1
              // },
              params:{wxImg:data,id: this.orderId,from: "order",type:1}
            });
            console.log(5555, data)
            break;
          case 2:
            // const div = document.createElement("div");
            // div.innerHTML = data;
            // document.body.appendChild(div);
            // // document.forms[0].acceptCharset = "GBK"; //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
            // document.forms[0].submit();
            const newWindow = window.open("", "_self");
            newWindow.document.write(data);
            newWindow.focus();
            // this.addExpress();
            break;
          //余额支付
          case 5:
            // this.getOrderDetail();
            this.$router.push({
              path: "/orderlist"
            });
            break;
          //余额支付
          case 4:
            this.$router.push({
              path: "/pay",
              query: {
                id: this.orderId,
              },
            });
            break;
          case 6:
            console.log("支付方式就："+this.pay_ways)
            if(this.pay_ways==1){
              this.$router.push({
                name: "Pay",
                // query: {
                //   id: this.orderId,
                //   from: "order",
                //   type:1
                // },
                params:{wxImg:data,id: this.orderId,from: "order",type:1}
              });
            }else if (this.pay_ways ==2){
              const newWindow = window.open("", "_self");
              newWindow.document.write(data);
              newWindow.focus();
            }
            break;
          default:
            break;
        }
      }
    },
  },
  created() {
    this.orderId = this.$route.query.id;
    this.getOrderDetail();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.icon-box {
  display: flex;

  .wx-icon {
    width: 50px;
    height: 50px;
  }

  .wx-text {
    box-sizing: border-box;
    padding: 15px 10px;
    background: #00b11f;
    color: #fff;
    border-radius: 15px;
    letter-spacing: 4px;
    font-size: 20px;
  }
}

.wx-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  .wx-img {
    width: 400px;
    height: 400px;
  }
}


.navtop {
  border-bottom: none;
}

.info-box {
  padding: 58px 24px 46px;
  border: $-solid-border;
  margin-top: -20px;

  .info-content {
    line-height: 2;
    margin-bottom: 40px;

    .content-item {
      align-items: flex-start;
    }
  }

  .info-table {
    .table-item {
      background-color: #fafafa;
      border-bottom: $-solid-border;

      &.item-th {
        padding: 15px 0;
        background-color: $-color-white;
        border-bottom: none;
      }

      .pd {
        padding-left: 114px;
      }

      .item-pro {
        padding: 30px 0;
        line-height: 1.5;

        .img {
          width: 64px;
          height: 64px;
          margin-right: 20px;
          margin-left: 32px;
          flex-shrink: 0;
        }
      }
    }
  }

  button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: $-color-border;
    cursor: pointer;

    &:hover {
      background-color: $-color-primary;
      color: white;
    }
  }
}

.payment {
  .pay-type {
    /deep/ .el-radio-group {
      display: flex;

      // justify-content: space-between;
      .el-radio {
        display: flex;
        align-items: center;
        margin-right: 60px;
        color: #666;

        .el-radio__input {
          &.is-checked {
            .el-radio__inner {
              background-color: transparent;
              border-color: transparent;

              &::after {
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url("~@/assets/icon/icon-select.png");
                transform: none;
                transition: none;
              }
            }

            &+.el-radio__label {
              color: $-color-primary;
            }
          }
        }

        .el-radio__inner {
          width: 20px;
          height: 20px;
        }

        .el-radio__label {
          font-size: 20px;
        }
      }
    }
  }

  .pay-list {
    align-items: flex-start;

    .pay-item {
      width: 296px;

      .item-select {
        cursor: pointer;
        margin: 5px 0;

        &.active {
          .select-box {
            &::after {
              content: "";
              position: absolute;
              width: 8px;
              height: 8px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) scale(1);
              background-color: $-color-primary;
              border-radius: 50%;
            }
          }

          .img-box {
            border: 2px solid $-color-primary;
            background: #fffced;
            border-radius: 1px;
          }
        }

        .select-box {
          width: 14px;
          height: 14px;
          border: $-solid-border;
          border-radius: 50%;
          margin-right: 34px;
          position: relative;
        }

        .img-box {
          padding: 10px 15px;

          img {
            width: auto;
            height: 36px;
          }
        }
      }
    }
  }

  .pay-btn {
    height: 48px;
    line-height: 48px;
    padding: 0 20px;
  }
}
</style>